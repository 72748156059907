<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center" class="mb-2">
        <v-col cols="auto" class="pr-12">
          <h1>Places To Eat</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Places To Eat"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn depressed color="accent" v-on="on">
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Place To Eat</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="attractions"
        class="mt-4"
        no-data-text="No Place To Eat found"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="blue lighten-4 blue--text"
                v-on="on"
                class="mr-2"
              >
                <v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="red lighten-4 red--text"
                v-on="on"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Archive</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <booking-dialog ref="bookingDialog" />
    <party-dialog ref="partyDialog" />
  </div>
</template>

<script>
import { arnprior } from "@/modules/arnprior/mixins";

export default {
  mixins: [arnprior],

  data() {
    return {
      breadcrumbs: [
        {
          text: "Things To Do",
          disabled: true,
        },
        {
          text: "Places To Eat",
          disabled: true,
        },
      ],
      searchTerm: "",
      tableHeaders: [
        { text: "Name", value: "name" },
        { text: "Website URL", value: "website_url" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
    };
  },

  computed: {
    attractions() {
      return this.$store.getters["arnprior/attractionsStore/placesToEat"];
    },
  },
};
</script>
